import { Component } from '@angular/core';
import { UiStore } from '../../../../core/store/ui.store';

@Component({
  selector: 'app-form-error',
  standalone: true,
  imports: [],
  templateUrl: './form-error.component.html',
  styleUrl: './form-error.component.scss',
})
export class FormErrorComponent {
  constructor(public uiStore: UiStore) {}
}
